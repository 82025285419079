<template>
    <v-row>
        <v-col cols="12">
            <v-card>
                <v-card-text>
                    <v-slide-group v-model="model" center-active show-arrows>
                        <v-slide-item
                            v-for="n, i in ferramenta"
                            :key="i"
                            v-slot="{ active, toggle }"
                            :value="n.id"
                        >
                            <v-btn
                                class="mx-1"
                                :input-value="active"
                                active-class="primary"
                                depressed
                                @click="toggle"
                            >{{ n.titulo }}</v-btn>
                        </v-slide-item>
                    </v-slide-group>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col cols="12" v-if="model == 1">
            <CalculadoraPiso />
        </v-col>
        <v-col cols="12" v-else-if="model == 2">
            <CalculadoraForroPvc />
        </v-col>
        <v-col cols="12" v-else-if="model == 3">
            <CalculadoraTijolo />
        </v-col>
        <v-col cols="12" v-else>
            <v-card>
                <v-card-text>Nenhuma ferramenta selecionada</v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import CalculadoraPiso from "./FerramentaCalculadoraPiso";
import CalculadoraForroPvc from "./FerramentaCalculadoraForroPvc";
import CalculadoraTijolo from "./FerramentaCalculadoraTijolo";

export default {
    name: "Ferramentas",
    components: { CalculadoraPiso, CalculadoraForroPvc, CalculadoraTijolo },
    data: () => ({
        model: 1,
        ferramenta: [
            { id: 1, titulo: "Calculadora piso" },
            { id: 2, titulo: "Calculadora forro" },
            { id: 3, titulo: "Calculadora tijolo" },
        ],
    }),
    methods: {},
    created() {},
};
</script>

<style scoped>
</style>