<template>
    <v-card>
        <v-card-title>Calculadora de pisos</v-card-title>
        <v-card-text>
            <v-row no-gutters>
                <v-col cols="12" md="6">
                    <v-row no-gutters class="card-calculadora">
                        <v-col cols="12" class="mb-3">
                            <span class="text-h6 font-weight-bold px-1">Dimensões da área</span>
                        </v-col>
                        <v-col cols="12" md="4" class="px-1">
                            <v-text-field
                                class="mb-n4"
                                type="number"
                                outlined
                                dense
                                color="primary"
                                label="Largura (metros):"
                                v-model.number="larguraAmbiente"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4" class="px-1">
                            <v-text-field
                                class="mb-n4"
                                type="number"
                                outlined
                                dense
                                color="primary"
                                label="Comprimento (metros):"
                                v-model.number="comprimentoAmbiente"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4" class="px-1">
                            <v-text-field
                                class="mb-n4"
                                type="number"
                                outlined
                                dense
                                color="primary"
                                label="Área (metros²):"
                                v-model.number="areaAmbiente"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" md="6">
                    <v-row no-gutters class="card-calculadora">
                        <v-col cols="12" class="mb-3">
                            <span class="text-h6 font-weight-bold px-1">Tamanho do piso</span>
                        </v-col>
                        <v-col cols="12" md="4" class="px-1">
                            <v-text-field
                                class="mb-n4"
                                type="number"
                                outlined
                                dense
                                color="primary"
                                label="Largura (cm):"
                                v-model.number="larguraPiso"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4" class="px-1">
                            <v-text-field
                                class="mb-n4"
                                type="number"
                                outlined
                                dense
                                color="primary"
                                label="Comprimento (cm):"
                                v-model.number="comprimentoPiso"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4" class="px-1">
                            <v-text-field
                                class="mb-n4"
                                type="number"
                                outlined
                                dense
                                color="primary"
                                label="Peças por caixa:"
                                v-model.number="pecasCaixaPiso"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12">
                    <v-row no-gutters class="card-calculadora">
                        <v-col cols="12" class="mb-3">
                            <span class="text-h6 font-weight-bold px-1">Resultado</span>
                        </v-col>
                        <v-col cols="12" md="3" class="px-1">
                            <v-text-field
                                class="mb-n4"
                                type="number"
                                outlined
                                dense
                                color="primary"
                                label="Total de peças"
                                readonly
                                :value="(areaAmbiente / ((larguraPiso/100) * (comprimentoPiso/100))).toFixed(2)"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="3" class="px-1">
                            <v-text-field
                                class="mb-n4"
                                type="number"
                                outlined
                                dense
                                color="primary"
                                label="Total de peças com sobra de 10%"
                                readonly
                                :value="((areaAmbiente / ((larguraPiso/100) * (comprimentoPiso/100))) * 1.1).toFixed(2)"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="3" class="px-1">
                            <v-text-field
                                class="mb-n4"
                                type="number"
                                outlined
                                dense
                                color="primary"
                                label="Total de caixas"
                                readonly
                                :value="((areaAmbiente / ((larguraPiso/100) * (comprimentoPiso/100))) / pecasCaixaPiso).toFixed(2)"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="3" class="px-1">
                            <v-text-field
                                class="mb-n4"
                                type="number"
                                outlined
                                dense
                                color="primary"
                                label="Total de caixas com sobra de 10%"
                                readonly
                                :value="(((areaAmbiente / ((larguraPiso/100) * (comprimentoPiso/100))) / pecasCaixaPiso) * 1.1).toFixed(2)"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    name: "FerramentaCalculadoraPiso",
    data: () => ({
        larguraAmbiente: 1,
        comprimentoAmbiente: 1,
        areaAmbiente: 1,
        larguraPiso: 45,
        comprimentoPiso: 45,
        pecasCaixaPiso: 10,
    }),
    methods: {},
    watch: {
        larguraAmbiente: function () {
            this.areaAmbiente = Math.ceil(
                this.larguraAmbiente * this.comprimentoAmbiente
            );
        },
        comprimentoAmbiente: function () {
            this.areaAmbiente = this.larguraAmbiente * this.comprimentoAmbiente;
        },
    },
    created() {},
};
</script>

<style scoped>
.card-calculadora {
    border: 2px solid #023449;
    border-radius: 5px;
    padding: 5px;
    margin: 5px;
}
</style>