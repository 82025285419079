<template>
    <v-card>
        <v-card-title>Calculadora de tijolo</v-card-title>
        <v-card-text>
            <v-row no-gutters>
                <v-col cols="12">
                    <v-row no-gutters class="card-calculadora">
                        <v-col cols="12" class="mb-3">
                            <span class="text-h6 font-weight-bold px-1">Medida parede</span>
                        </v-col>
                        <v-col cols="12" md="4" class="px-1">
                            <v-text-field
                                class="mb-n4"
                                outlined
                                dense
                                type="number"
                                label="Comprimento do muro (metros)"
                                v-model.number="comprimentoMuro"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4" class="px-1">
                            <v-text-field
                                class="mb-n4"
                                outlined
                                dense
                                type="number"
                                label="Altura do muro (metros)"
                                v-model.number="alturaMuro"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4" class="px-1">
                            <v-text-field
                                class="mb-n4"
                                outlined
                                dense
                                type="number"
                                label="Área (m²)"
                                v-model.number="areaMuro"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12">
                    <v-row no-gutters class="card-calculadora">
                        <v-col cols="12" class="mb-3">
                            <span class="text-h6 font-weight-bold px-1">Resultado</span>
                        </v-col>
                        <v-col cols="12">
                            <v-simple-table dense>
                                <thead>
                                    <tr>
                                        <th>Tamanho tijolo</th>
                                        <th class="text-center text-truncate">Tijolo em pé</th>
                                        <th class="text-center text-truncate">Tijolo em pé (+10%))</th>
                                        <th class="text-center text-truncate">Tijolo deitado</th>
                                        <th class="text-center text-truncate">Tijolo deitado (+10%)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(t, i) in tamanhos" :key="i">
                                        <td class="text-truncate">{{t.tamanho}}</td>
                                        <td
                                            class="text-center"
                                        >{{((areaMuro)/((t.comprimento / 100) * (t.altura / 100))).toFixed(2)}} un</td>
                                        <td
                                            class="text-center"
                                        >{{((areaMuro)/((t.comprimento / 100) * (t.altura / 100)) * 1.1).toFixed(2)}} un</td>
                                        <td
                                            class="text-center"
                                        >{{((areaMuro)/((t.comprimento / 100) * (t.largura / 100))).toFixed(2)}} un</td>
                                        <td
                                            class="text-center"
                                        >{{((areaMuro)/((t.comprimento / 100) * (t.largura / 100)) * 1.1).toFixed(2)}} un</td>
                                    </tr>
                                </tbody>
                            </v-simple-table>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    name: "FerramentaCalculadoraTijolo",
    data: () => ({
        alturaMuro: 1,
        comprimentoMuro: 1,
        areaMuro: 1,
        tamanhos: [
            {
                id: 1,
                tamanho: "Tijolo 9x14x19",
                largura: 9,
                altura: 14,
                comprimento: 19,
            },
            {
                id: 2,
                tamanho: "Tijolo 9x14x24",
                largura: 9,
                altura: 14,
                comprimento: 24,
            },
            {
                id: 3,
                tamanho: "Tijolo 11,5x14x24",
                largura: 11.5,
                altura: 14,
                comprimento: 24,
            },
        ],
        tamanho: 3,
    }),
    methods: {},
    watch: {
        alturaMuro: function () {
            this.areaMuro = Math.ceil(this.alturaMuro * this.comprimentoMuro);
        },
        comprimentoMuro: function () {
            this.areaMuro = this.alturaMuro * this.comprimentoMuro;
        },
    },
    created() {},
};
</script>

<style scoped>
.card-calculadora {
    border: 2px solid #023449;
    border-radius: 5px;
    padding: 5px;
    margin: 5px;
}
</style>