<template>
    <v-card>
        <v-card-title>Calculadora de forro PVC</v-card-title>
        <v-card-text>
            <v-row no-gutters>
                <v-col cols="12">
                    <v-row no-gutters class="card-calculadora">
                        <v-col cols="12" class="mb-3">
                            <span class="text-h6 font-weight-bold px-1">Medida ambiente</span>
                        </v-col>
                        <v-col cols="12" md="3" class="px-1">
                            <v-text-field
                                class="mb-n4"
                                outlined
                                dense
                                type="number"
                                label="Comprimento do ambiente (metros)"
                                v-model.number="comprimentoAmbiente"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="3" class="px-1">
                            <v-text-field
                                class="mb-n4"
                                outlined
                                dense
                                type="number"
                                label="Largura do ambiente (metros)"
                                v-model.number="larguraAmbiente"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="3" class="px-1">
                            <v-text-field
                                class="mb-n4"
                                outlined
                                dense
                                type="number"
                                label="Área (m²)"
                                v-model.number="areaAmbiente"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="3" class="px-1">
                            <v-text-field
                                class="mb-n4"
                                outlined
                                dense
                                disabled
                                label="Perímetro (metros)"
                                :value="(comprimentoAmbiente * 2) + (larguraAmbiente * 2)"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12">
                    <v-row no-gutters class="card-calculadora">
                        <v-col cols="12" class="mb-3">
                            <span class="text-h6 font-weight-bold px-1">Resultado</span>
                        </v-col>
                        <v-col cols="12">
                            <v-simple-table dense>
                                <thead>
                                    <tr>
                                        <th>Forro</th>
                                        <th class="text-center">Comprimento</th>
                                        <th class="text-center">Largura</th>
                                        <th class="text-center text-truncate">Peças necessárias</th>
                                        <th class="text-center text-truncate">Peças com sobra de 10%</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(t, i) in tamanhos" :key="i">
                                        <td class="text-truncate">{{t.tamanho}}</td>
                                        <td class="text-center">{{t.comprimento}} m</td>
                                        <td class="text-center">{{t.largura}} m</td>
                                        <td
                                            class="text-center"
                                        >{{((areaAmbiente)/(t.comprimento * t.largura)).toFixed(2)}}</td>
                                        <td
                                            class="text-center"
                                        >{{((areaAmbiente)/(t.comprimento * t.largura) * 1.1).toFixed(2)}}</td>
                                    </tr>
                                </tbody>
                            </v-simple-table>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    name: "FerramentaCalculadoraPiso",
    data: () => ({
        larguraAmbiente: 1,
        comprimentoAmbiente: 1,
        areaAmbiente: 1,
        tamanhos: [
            { id: 1, tamanho: "Forro 0,20 x 4m", comprimento: 4, largura: 0.2 },
            { id: 2, tamanho: "Forro 0,20 x 5m", comprimento: 5, largura: 0.2 },
            { id: 3, tamanho: "Forro 0,20 x 6m", comprimento: 6, largura: 0.2 },
            { id: 4, tamanho: "Forro 0,20 x 7m", comprimento: 7, largura: 0.2 },
            { id: 5, tamanho: "Forro 0,20 x 8m", comprimento: 8, largura: 0.2 },
            { id: 6, tamanho: "Forro 0,20 x 9m", comprimento: 9, largura: 0.2 },
            {
                id: 7,
                tamanho: "Forro 0,20 x 10m",
                comprimento: 10,
                largura: 0.2,
            },
        ],
        tamanho: 3,
    }),
    methods: {},
    watch: {
        larguraAmbiente: function () {
            this.areaAmbiente = Math.ceil(
                this.larguraAmbiente * this.comprimentoAmbiente
            );
        },
        comprimentoAmbiente: function () {
            this.areaAmbiente = this.larguraAmbiente * this.comprimentoAmbiente;
        },
    },
    created() {},
};
</script>

<style scoped>
.card-calculadora {
    border: 2px solid #023449;
    border-radius: 5px;
    padding: 5px;
    margin: 5px;
}
</style>